<template>
	<section class="container-fluid py-5">
		<div class="d-flex flex-column align-items-center">
			<h1 class="mb-4">Download overblik over alle aktive aftaler</h1>
			<p class="text-center mb-4">Download detaljerede rapporter med blot ét klik - det kan tage op til 2-3 minutter</p>
			<BaseActionButton type="button" class="btn btn-primary mb-4" v-on:on-click="generateRapport" :disabled="isLoading">
				<span v-if="isLoading">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					Indlæser...
				</span>
				<span v-else> Lav rapport </span>
			</BaseActionButton>
			<xlsx-workbook v-if="rapportIsReady" class="mb-4">
				<xlsx-sheet :collection="sheet.data" v-for="sheet in getExcelFormattedData" :key="sheet.name" :sheet-name="sheet.name" />
				<xlsx-download :filename="getFileName">
					<b-button class="btn btn-success">Download rapport - {{ getFileName }}</b-button>
				</xlsx-download>
			</xlsx-workbook>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import config from '../../../../../conf.json'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'

export default {
	name: 'Rapport',
	components: {
		XlsxWorkbook,
		XlsxSheet,
		XlsxDownload,
	},
	data() {
		return {
			isReady: false,
			rapportIsReady: false,
			csvArray: [],
			isLoading: false,
			transferArray: [],
			custodianValueArray: [],
		}
	},
	created() {
		this.$emit('updateLoader')
	},
	methods: {
		async generateRapport() {
			this.isLoading = true
			try {
				// Call the API to generate the rapport
				let idToken = await this.$store.getters['auth/idToken']

				var options = {
					url: config.firebaseConfig.apiURL + '/integration/csv',
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
				var result = await axios(options)
				this.isLoading = false

				this.csvArray = result.data
				// Remove all null from array
				this.csvArray = this.csvArray.filter((el) => {
					return el != null
				})

				var transferArray = []
				var custodianValueArray = []
				this.csvArray.forEach((element) => {
					// Parse date to excel format for Pensionstart, Sundhedsforsikring startdato, Fratrædelsesdato, Ansættelsedato,

					element['Pensionstart'] = element['Pensionstart'] ? new Date(element['Pensionstart']).toLocaleDateString('da-DK') : ''
					element['Sundhedsforsikring startdato'] = element['Sundhedsforsikring startdato']
						? new Date(element['Sundhedsforsikring startdato']).toLocaleDateString('da-DK')
						: ''
					element['Fratrædelsesdato'] = element['Fratrædelsesdato'] ? new Date(element['Fratrædelsesdato']).toLocaleDateString('da-DK') : ''
					element['Ansættelsedato'] = element['Ansættelsedato'] ? new Date(element['Ansættelsedato']).toLocaleDateString('da-DK') : ''

					transferArray.push(element['Overførsel'])
					custodianValueArray.push(element['Depotværdi'])
					delete element['Overførsel']
					delete element['Depotværdi']
				})

				// Format all custodian dato

				custodianValueArray.forEach((element) => {
					element.forEach((el) => {
						el['Dato'] = el['Dato'] ? new Date(el['Dato']).toLocaleDateString('da-DK') : ''
					})
				})

				transferArray.forEach((element) => {
					element.forEach((el) => {
						el['Dato'] = el['Dato'] ? new Date(el['Dato']).toLocaleDateString('da-DK') : ''
					})
				})

				// Flat the array
				this.transferArray = transferArray.flat()
				this.custodianValueArray = custodianValueArray.flat()
				this.rapportIsReady = true
			} catch (error) {
				this.isLoading = false
				this.$toast.error('Der skete en fejl ved generering af rapporten')
			}
		},
	},
	computed: {
		getFileName() {
			return `Kundeportal_medarbejderlisten_${this.getTodayDateFormatted}.xlsx`
		},
		getTodayDateFormatted() {
			let today = new Date()
			let dd = String(today.getDate()).padStart(2, '0')
			let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
			let yyyy = today.getFullYear()

			today = dd + '/' + mm + '/' + yyyy
			return today
		},
		getExcelFormattedData() {
			let sheet = [
				{
					name: 'Medarbejderlisten',
					data: this.csvArray,
				},
				{
					name: 'Overførsel',
					data: this.transferArray,
				},
				{
					name: 'Depot værdi',
					data: this.custodianValueArray,
				},
			]

			return sheet
		},
	},
}
</script>

<style scoped>
.container-fluid {
	background-color: #f8f9fa;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
	font-size: 2rem;
	color: #343a40;
}

p {
	font-size: 1.2rem;
	color: #6c757d;
}

.btn-primary {
	background-color: #007bff;
	border-color: #007bff;
}

.btn-success {
	background-color: #28a745;
	border-color: #28a745;
}
</style>
